import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
// import Hotjar from "@hotjar/browser";
import "./App.css";
import "simplebar-react/dist/simplebar.min.css";

import PulseLoader from "react-spinners/PulseLoader";

import ShopData from "./Components/Shop/ShopData/ShopData";
import TimingDialog from "./Components/Shop/Timings/TimingsDialog";
import Home from "./Components/Home/Home";
import Profile from "./Components/Users/UserProfile/Profile";
import Notifier from "./Components/UI/Notifier/Notifier";
import OrdersContainer from "./Components/Orders/OrdersContainer/OrdersContainer";
import Terms from "./Components/UI/Dialogues/Terms";
import DataPolicy from "./Components/UI/Dialogues/DataPolicy";
import InfoDialog from "./Components/UI/Reporting/ReportDialog";
// import Footer from './Components/UI/Footer/Footer';

import AreaSelection from "./Components/UI/Dialogues/AreaSelection";
import UserDialog from "./Components/Users/UserDialog/UserDialog";
import CookieDialog from "./Components/UI/Dialogues/CookieDialog";
import MobileAppDialog from "./Components/UI/Dialogues/MobileAppDialog";
import Device from "./Components/Users/Device/Device";
import { browserName } from "react-device-detect";
import reactManifest from "react-manifest";

// socket
import { io } from "socket.io-client";
import {
  ordersContainerToggle,
  selectAreaToggle,
  setIncompleteOrdersCount,
  setSocketId,
  setTrackerActiveStep,
  setTrackerDelayed,
  setTrackerDuration,
  setTrackerReason,
  setTrackerRejected,
  toggleNewOrder,
  updatePaymentStatusStripe,
  setOrderTime,
} from "./ToolKit/Slices/OrderSlice";
import {
  checkIsShopCurrentlyOpen,
  fetchShop,
  setIntervals,
  setServerTime,
  updatePickupDeliveryTime,
} from "./ToolKit/Slices/ShopSlice";
import {
  VerifyAuth,
  deleteAccountToggle,
  logoutUser,
  profileToggle,
  registerWithOrder,
  setNotificationPermission,
  setSmartBar,
  userDialogToggle,
} from "./ToolKit/Slices/UserSlice";
import { fetchMenu, setAllShopCategories } from "./ToolKit/Slices/MenuSlice";
import { fetchToppings } from "./ToolKit/Slices/ToppingSlice";
import jwtDecode from "jwt-decode";
import VacationsDialog from "./Components/Shop/Timings/VacationsDialog";

import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import { regSw, subscribe } from "./utils/swHelper";
import DrinkDialog from "./Components/Orders/PlaceOrder/DrinkDialog";
import axios from "axios";
import { setOrderType } from "./ToolKit/Slices/CartSlice";
import IdleDetection from "./Components/IdleDetection/IdleDetection";

export const socket = io(import.meta.env.VITE_API_URL);

const App = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  // STATES
  const shopData = useSelector((state) => state.shop.shopData);
  const pickupIntervals = useSelector((state) => state.shop?.pickupTimeIntervals);
  const deliveryIntervals = useSelector((state) => state.shop?.deliveryTimeIntervals);
  const separateTimings = useSelector((state) => state.shop?.settings?.separateTimings);
  const orderType = useSelector((state) => state.cart.orderType);
  const showAreaSelection = useSelector((state) => state.order.showAreaSelection);
  const shopDataVisible = useSelector((state) => state.shop.shopDataVisible);
  const userDialogVisible = useSelector((state) => state.user.userDialogVisible);
  const timeDialogVisible = useSelector((state) => state.shop.timeDialogVisible);
  const userLoading = useSelector((state) => state.user.isLoading);
  const profileVisible = useSelector((state) => state.user.profileVisible);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const ordersContainerVisible = useSelector((state) => state.order.ordersContainerVisible);
  const termsVisible = useSelector((state) => state.shop.termsVisible);
  const policyVisible = useSelector((state) => state.shop.policyVisible);
  const cookieAccepted = useSelector((state) => state.user.cookieAccepted);
  const viewSmartBar = useSelector((state) => state.user.viewSmartBar);
  const stripeButtonLoading = useSelector((state) => state.order.stripeButtonLoading);
  const hideSmartBar = useSelector((state) => state.user.hideSmartBar);
  const infoClicked = useSelector((state) => state.shop.infoClicked);
  const appAdActive = useSelector((state) => state.shop.homepage.appAdActive);
  const currUser = useSelector((state) => state.user.currUser);
  const userAddresses = useSelector((state) => state.user.addresses);

  const isEighteenPlusItemDialogOpen = useSelector(
    (state) => state.order.isEighteenPlusItemDialogOpen
  );
  const isDeliveryAvailable = useSelector((state) => state.shop.isDeliveryAvailable);
  const isPickupAvailable = useSelector((state) => state.shop.isPickupAvailable);
  const shopOpeningDate = useSelector((state) => state.shop.shopOpeningDate);
  const userOrders = useSelector((state) => state.user.orders);
  const localOrders = useSelector((state) => state.order.localOrders);
  const manifest = useSelector((state) => state.shop.shopData?.manifest);
  const serverTimeDateEpoch = useSelector((state) => state.shop.serverTimeDateEpoch);
  const serverTime = useSelector((state) => state.shop.serverTime);
  const vacationsClosing = useSelector((state) => state.shop.settings?.vacationsClosing);
  const manualClosing = useSelector((state) => state.shop.settings?.manualClosing);
  const todayTiming = useSelector((state) => state.shop.todayTiming);
  const serverDate = useSelector((state) => state.shop.serverDate);
  const vacationsDialogVisible = useSelector((state) => state.shop.vacationsDialogOpen);
  const categories = useSelector((state) => state.menu.categories);
  const settings = useSelector((state) => state.shop.settings);
  const stripeSession = useSelector((state) => state.order.stripeSession);
  const defaultDeliveryTime = settings?.livePanelSettings?.defaultDeliveryTime;
  const defaultPickupTime = settings?.livePanelSettings?.defaultPickupTime;
  const delayedTime = useSelector((state) => state.order.delayedTime);
  const delayedOrder = useSelector((state) => state.order.delayedOrder);

  const orderTypeCart = useSelector((state) => state.cart.orderType);

  // ############ sevice worker start here ############
  async function registerAndSubscribe() {
    try {
      const serviceWorkerReg = await regSw();
      await subscribe(serviceWorkerReg, null);
    } catch (error) {
      console.log(error);
    }
  }
  // ############ sevice worker ends here ############

  const showSnack = (message, type, persist) => {
    enqueueSnackbar(message, {
      variant: type,
      persist: persist,
    });
  };

  useEffect(() => {
    if (window.location.pathname === "/delete-account") {
      if (currUser) {
        dispatch(deleteAccountToggle(true));
        dispatch(profileToggle(true));
      } else {
        dispatch(userDialogToggle({ toggle: true, userAction: "login" }));
      }
    }
  }, []);

  useEffect(() => {
    console.log("window.location.pathname", window.location.pathname);
    if (window.location.pathname === "/kiosk") {
      dispatch(setOrderType({ orderTypeLocal: "kiosk", deliveryCharges: 0 }));
    } else {
      if (orderTypeCart === "kiosk") {
        dispatch(setOrderType({ orderTypeLocal: "", deliveryCharges: 0 }));
      }
    }
  }, []);

  const browserHasNotification = window.Notification ? Notification?.permission : null;

  useEffect(() => {
    // console.log('localStorage.getItem("subscription")', localStorage.getItem("subscription"));
    if (browserHasNotification) {
      if (Notification?.permission === "granted" && localStorage.getItem("subscription") === null) {
        registerAndSubscribe();
      }
    }
  }, []);

  const check = () => {
    dispatch(setServerTime());
  };

  useEffect(() => {
    if (serverTime) {
      const payloadObject = {
        todayTiming,
        serverTime,
        manualClosing,
        vacationsClosing,
        serverDate,
        shopOpeningDate,
        separateTimings,
      };
      dispatch(checkIsShopCurrentlyOpen(payloadObject));
    }
  }, [serverTime]);

  useEffect(() => {
    if (manifest) {
      const faviconTag = document.getElementById("shop-favicon");
      faviconTag.href = manifest["64x64"] !== "" ? manifest["64x64"] : manifest["120xauto"];

      const manifestDetails = {
        short_name: manifest?.shortName,
        name: manifest?.name,
        description: manifest?.description,

        icons: [
          // common
          {
            src: manifest["64x64"] !== "" ? manifest["64x64"] : manifest["120xauto"],
            // src: "https://firebasestorage.googleapis.com/v0/b/react-pizza-shared.appspot.com/o/common%2Ftab-logo%2Forionid-favicon.svg?alt=media&token=554b9c49-59a0-4a24-9931-6088802c16b5",
            sizes: "64x64 32x32 24x24 16x16",
            type: "image/x-icon",
            purpose: "any",
          },

          // {
          //   src: manifest["120xauto"],

          //   type: "image/svg+xml",
          //   sizes: "144x144",
          //   purpose:"any"
          // },
          {
            src: manifest["192x192"] !== "" ? manifest["192x192"] : manifest["120xauto"],

            type: manifest["192x192"] !== "" ? "image/png" : "image/svg+xml",

            sizes: "192x192",
            purpose: "any",
          },

          {
            src: manifest["512x512"] !== "" ? manifest["512x512"] : manifest["120xauto"],

            type: manifest["512x512"] !== "" ? "image/png" : "image/svg+xml",
            sizes: "512x512",
            purpose: "any",
          },
        ],
        start_url: window.location.href,
        // start_url: "/?",
        display: "standalone",
        theme_color: "#000000",
        background_color: "#ffffff",
      };

      reactManifest.update(manifestDetails, "#manifest-placeholder");
    }
  }, [manifest]);

  useEffect(() => {
    let timer = setInterval(check, 1000 * 60 * 5);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    let cats = 0;
    if (categories) {
      if (loggedIn) {
        cats = categories.length + 2;

        dispatch(setAllShopCategories(cats));
      } else {
        cats = categories.length + 1;

        dispatch(setAllShopCategories(cats));
      }
    }
  }, [loggedIn, categories]);

  useEffect(() => {
    if (!window.Notification) {
    } else {
      dispatch(setNotificationPermission({ notify: Notification?.permission }));
    }
    return () => {};
  }, [browserHasNotification]);

  useEffect(() => {
    if (separateTimings && shopData && orderType === "") {
      dispatch(selectAreaToggle(true));
    }
  }, [orderType, separateTimings, shopData]);

  // console.log("delayedTime", delayedTime);
  // console.log("delayedOrder", delayedOrder);
  // console.log("outside -------");
  // console.log("=============================");

  // console.log("separateTimings", separateTimings);
  // console.log("shopData", shopData);
  // console.log("orderType", orderType);
  // console.log("isDeliveryAvailable, isPickupAvailable", isDeliveryAvailable, isPickupAvailable);
  // console.log("=============================");

  useEffect(() => {
    if (orderType !== "" && separateTimings) {
      if (orderType === "delivery") {
        dispatch(setIntervals(deliveryIntervals));
        if (!isDeliveryAvailable?.status && isDeliveryAvailable?.message === "less") {
          dispatch(setOrderTime({ time: "Choose Time", delayed: true }));
          console.log("delayedTime", delayedTime);
          console.log("delayedOrder", delayedOrder);
        } else {
          dispatch(setOrderTime({ time: "As soon as possible", delayed: false }));
        }
      } else if (orderType === "pickup") {
        dispatch(setIntervals(pickupIntervals));
        if (!isPickupAvailable?.status && isPickupAvailable?.message === "less") {
          dispatch(setOrderTime({ time: "Choose Time", delayed: true }));
        } else {
          dispatch(setOrderTime({ time: "As soon as possible", delayed: false }));
        }
      }
    }
  }, [orderType, separateTimings, isDeliveryAvailable, isPickupAvailable]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("statusChange", (status) => {
      console.log("Order status change: ", status);
      if (status.status === null) dispatch(setTrackerActiveStep({ count: 0, id: status.orderId }));
      if (status.status === "preparing") {
        // if (notificationPermission === "granted") showDesktopNotification(status);
        dispatch(setTrackerActiveStep({ count: 1, id: parseInt(status.orderId) }));
        dispatch(setTrackerDuration({ duration: status.duration, id: status.orderId }));
        showSnack(
          `(Bestellung # ${status.orderSerial}) ${t("tracker.Order Accepted Now preparing")}` +
            (status.orderType === "delivery"
              ? "Voraussichtliche Lieferzeit: "
              : "Voraussichtliche Abholzeit: ") +
            new Date(status.duration).toLocaleTimeString("de-DE").slice(0, 5),
          "info",
          true
        );
      }
      if (status.status === "enroute" || status.status === "ready") {
        // if (notificationPermission === "granted") showDesktopNotification(status);
        dispatch(setTrackerActiveStep({ count: 2, id: status.orderId }));
        showSnack(
          `(Bestellung # ${status.orderSerial})` +
            (status.orderType === "delivery"
              ? t("tracker.Your order is on the way")
              : t("tracker.Your order is ready to pickup")),
          "info",
          true
        );
      }
      if (status.status === "completed") {
        // if (notificationPermission === "granted") showDesktopNotification(status);
        dispatch(setTrackerActiveStep({ count: 2, id: status.orderId }));
      }
      if (status.status === "rejected") {
        // if (notificationPermission === "granted") showDesktopNotification(status);
        dispatch(setTrackerRejected({ status: true, id: status.orderId }));
        dispatch(setTrackerReason({ reason: status.reason, id: status.orderId }));
        showSnack(
          `(Bestellung # ${status.orderSerial}) ${
            t("tracker.Order rejected by shopkeeper") + ".. " + status.reason
          }`,
          "info",
          true
        );
      }
      if (status.status === "delayed") {
        // if (notificationPermission === "granted") showDesktopNotification(status);
        dispatch(setTrackerDelayed({ status: true, id: status.orderId }));
      }
      if (status.status === "update") {
        // if (notificationPermission === "granted") showDesktopNotification(status);
        dispatch(setTrackerDuration({ duration: status.duration, id: status.orderId }));
        showSnack(
          `(Bestellung # ${status.orderSerial}) ${t("tracker.expectedDeliveryTimeChangedBy")}(${
            status.change
          })`,
          "info",
          true
        );
      }
    });

    socket.on("pickup_delivery_time_change", (times) => {
      // console.log("times", times);
      if (defaultPickupTime !== times.pickupTime) {
        showSnack(` ${t("Pickup time changed to")} ${times.pickupTime}`, "info", true);
      }

      if (defaultDeliveryTime !== times.deliveryTime) {
        showSnack(` ${t("Delivery time changed to")} ${times.deliveryTime}`, "info", true);
      }

      dispatch(updatePickupDeliveryTime(times));
    });
    socket.on("disconnect", () => {
      console.log("Disconnected to server");
      socket.emit("clientLogout");
    });
    socket.on("connect_error", (err) => {
      console.log(`websocket connect_error due to ${err.message}`);
    });
    socket.on("closeAlert", (status) => {
      console.log("Close Alert!");
      // localStorage.clear();
      sessionStorage.clear();

      setTimeout(() => {
        window.location.reload();
      }, 3000);
      // showSnack(
      //   `Please Reload The Website To Avoid Any Inconvenience`,
      //   "warn",
      //   true
      // );
      // dispatch(fetchShop({ currUser, userOrders }));
    });

    socket.on("reloadclient", (err) => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });

    dispatch(setSocketId(socket.id));

    return () => {
      socket.off();
    };
  }, []);

  useEffect(() => {
    if (serverTimeDateEpoch) {
      const now = serverTimeDateEpoch;

      if (currUser && (Array.isArray(userOrders) ? userOrders[0] : false)) {
        const incompleteOrders = userOrders.filter((o) =>
          o
            ? o.status !== "completed" && now - new Date(o.orderTimestamp).getTime() < 64800000 // 64800000 miliseconds = 18 hours
            : false
        );
        dispatch(setIncompleteOrdersCount({ incOrders: incompleteOrders.length }));

        incompleteOrders.forEach((i) => {
          socket.emit("join", i.id);
          console.log("joining room userOrder:>> ", i.id);
        });
      }
      if (currUser === null && localOrders.length > 0) {
        // if (localOrders[0]) {
        const incompleteOrders = localOrders.filter(
          (o) => o.status !== "completed" && now - new Date(o.orderTimestamp).getTime() < 64800000
        );
        dispatch(setIncompleteOrdersCount({ incOrders: incompleteOrders.length }));
        incompleteOrders.forEach((i) => {
          socket.emit("join", i.id);
        });
        // }
      }
    }

    return () => {
      socket.off();
    };
  }, []);

  useEffect(() => {
    // return () => {
    //   socket.off();
    //   console.log(`socket turned off`);
    // };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      if (token !== "undefined") {
        const decoded = jwtDecode(token);

        if (decoded.userData?.id || decoded.orderIds) {
          const isUserToken = decoded.userData?.id ? true : false;

          dispatch(VerifyAuth({ isUserToken, currUser, localOrders })).then((response) => {
            console.log("response from auth", response);
            if (response?.error?.message === undefined) {
              dispatch(
                fetchShop({
                  currUser: response.payload.currUser,
                  userOrders: response.payload.userOrders,
                  userAddresses: response.payload.currUser.addresses,
                })
              );
              dispatch(fetchMenu());
              dispatch(fetchToppings());
            } else {
              dispatch(fetchShop({ currUser, userOrders, userAddresses }));
              dispatch(fetchMenu());
              dispatch(fetchToppings());
            }
            // --------------------- TO BE ADDED LATER -----------------
            // let addresses = response?.payload?.currUser?.addresses;
            // let selectedAddress = addresses.find((address) => address.selected === true);
            // console.log('selectedAddress', selectedAddress)
            // if (selectedAddress) {
            //   // if user saved address is available in db
            //   console.log('areas', areas)
            //   let selectedAddressAvailable = areas?.find(
            //     (area) => area.id === selectedAddress?.userAreaId
            //   );
            //   console.log('selectedAddressAvailable', selectedAddressAvailable)
            //   if (selectedAddressAvailable === undefined || !selectedAddressAvailable?.active) {
            //     showSnack(
            //       `${selectedAddress?.city}, ${selectedAddress?.postalCode}. ${t(
            //         "Delivery not available at this time"
            //       )}`,
            //       "error",
            //       true
            //     );
            //   }else{
            //     dispatch(selectAddressLocal({ addrId: address.addrId }));
            //   }
            // }
          });
        }
        if (decoded.user?.id) {
          localStorage.removeItem("token");
          dispatch(logoutUser());
          dispatch(fetchShop({ currUser, userOrders, userAddresses }));
          dispatch(fetchMenu());
          dispatch(fetchToppings());
        }
      } else {
        localStorage.removeItem("token");
        dispatch(logoutUser());
        dispatch(fetchShop({ currUser, userOrders, userAddresses }));
        dispatch(fetchMenu());
        dispatch(fetchToppings());
      }
    } else {
      dispatch(logoutUser());
      dispatch(fetchShop({ currUser, userOrders, userAddresses }));
      dispatch(fetchMenu());
      dispatch(fetchToppings());
    }
    // return () => {};
  }, []);

  useEffect(() => {
    // dispatch(fetchShop({ currUser, userOrders, userAddresses }));
    // dispatch(fetchMenu());
    // dispatch(fetchToppings());

    // dispatch(fetchUserOrders({ currUser, localOrders }));

    setTimeout(() => {
      dispatch(setSmartBar({ toggle: true }));
    }, 3000);
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    // window.location.pathname = "/";
    if (query.get("success")) {
      const orderId = query.get("order_id");
      const session_id = query.get("session_id");
      // window.alert(session_id);
      axios
        .post(`${import.meta.env.VITE_API_URL}/stripe/get-session-data`, {
          checkoutSessionId: session_id,
        })
        .then((res) => {
          console.log("res.data", res.data);
          if (res.data.payment_status === "paid") {
            dispatch(
              updatePaymentStatusStripe({
                orderId: orderId,
                payment: res.data?.payment_intent,
                status: "payed",
                currUser,
              })
            ).then((res1) => {
              // closeDialog(true);
              console.log("res1", res1.payload.responseData);
              if (stripeSession?.userData !== null) {
                dispatch(registerWithOrder(stripeSession.userData));
                dispatch(ordersContainerToggle(true));
                dispatch(toggleNewOrder(true));
              }
              if (window.location.search) {
                const newUrl = window.location.origin + window.location.pathname;
                window.history.replaceState(null, "", newUrl);
              }
            });
          } else {
            dispatch(
              updatePaymentStatusStripe({
                orderId: orderId,
                payment: res.data?.payment_intent,
                status: "failed",
                currUser,
              })
            ).then((res1) => {
              showSnack("Transaktion abgebrochen! Bestellung ist nicht abgeschlossen", "error");
            });
          }
        });
    }
    // if (query.get("canceled")) {
    //   // alert("Transaktion abgebrochen! Bestellung ist nicht abgeschlossen");
    //   // console.log("paypal transaction canceled");
    //   const status = "canceled";
    //   const paymentData = null;
    //   const orderId = query.get("order_id");
    //   dispatch(toggleStripeButtonLoading(true));
    //   setTimeout(() => {
    //     dispatch(
    //       updatePaymentStatusStripe({ status, payment: paymentData, orderId, currUser })
    //     ).then(() => {
    //       const urlWithoutQuery = window.location.origin + window.location.pathname;
    //       window.history.replaceState({}, document.title, urlWithoutQuery);
    //       dispatch(toggleStripeButtonLoading(false));
    //     });
    //   }, 2000);
    // setMessage(
    //   "Order canceled -- continue to shop around and checkout when you're ready."
    // );
    // }
  }, []);

  const shop = useSelector((state) => state.shop.shopData);
  const currOrder = useSelector((state) => state.order.currOrder);

  return (
    <div
      className="App"
      style={{
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.default : "",
        // backgroundColor: "black",
      }}
    >
      <IdleDetection />
      {/* <OrderPaper currOrder={currOrder} shop={shop} /> */}
      {stripeButtonLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <>
        {userLoading ? (
          <div style={{ paddingTop: "144px", height: "100vh" }}>
            <PulseLoader color={theme.palette.mode === "light" ? "#000" : "#fff"} />
          </div>
        ) : (
          <div
            style={{
              backgroundColor: theme.palette.mode === "dark" && theme.palette.background.default,
            }}
          >
            {infoClicked ? <InfoDialog /> : null}
            <Notifier />
            {browserName === "Chrome WebView" || browserName === "WebKit" ? null : viewSmartBar &&
              !hideSmartBar &&
              appAdActive ? (
              <MobileAppDialog />
            ) : null}
            {showAreaSelection && orderTypeCart !== "kiosk" ? <AreaSelection /> : null}
            {vacationsDialogVisible && orderTypeCart !== "kiosk" ? <VacationsDialog /> : null}
            {timeDialogVisible && orderTypeCart !== "kiosk" ? <TimingDialog /> : null}

            {shopDataVisible ? <ShopData /> : null}
            {userDialogVisible ? <UserDialog /> : null}
            {profileVisible ? <Profile /> : null}
            {ordersContainerVisible ? <OrdersContainer /> : null}
            {termsVisible ? <Terms /> : null}
            {policyVisible ? <DataPolicy /> : null}
            {cookieAccepted ? null : <CookieDialog />}
            {isEighteenPlusItemDialogOpen ? <DrinkDialog /> : null}
            <Device />
            <Home />
          </div>
        )}
      </>
    </div>
  );
};

export default App;
