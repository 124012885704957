import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import i18next from "i18next";

const daysArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const counter = (countArray, tCatId) => {
  let count = 0;
  countArray.forEach((tC) => {
    if (tC === tCatId) {
      count++;
    }
  });

  return count;
};

export const updateCounter = (count, tCatId) => {
  const idx = count.findIndex((i) => i === tCatId);
  count.splice(idx, 1);

  return count;
};

// export const grandTotalCalc = (subTotal, deliverycharges) => {

//     const grandTotal = (deliverycharges + subTotal);
//     return grandTotal;

// }

export const checkExisting = (toppings, tCatId) => {
  return toppings.find((topping) => topping.tCatId === tCatId);
};

export const checkExistingPrice = (toppings, tCatId) => {
  return toppings.find((topping) => topping.tCatId === tCatId).price;
};

export const decreaseLimit = (counter, tCatId, max, free, min) => {
  let retCounter = null;

  if (counter[0]) {
    retCounter = counter.map((tCat) => {
      if (tCat.tCatId === tCatId) {
        tCat.max = tCat.max - 1;
        tCat.free = tCat.free === 0 ? 0 : tCat.free - 1;
        tCat.min = tCat.min === 0 ? 0 : tCat.min - 1;
        return tCat;
      } else {
        return tCat;
      }
    });
  } else {
    retCounter = [{ tCatId: tCatId, max: max, free: free, min: min }];
  }
  return retCounter;
};

export const increaseLimit = (counter, tCatId, freeCount, count, minActual) => {
  let retCounter = null;

  retCounter = counter.map((tCat) => {
    if (tCat.tCatId === tCatId) {
      tCat.max = tCat.max + count;
      tCat.free = tCat.free + freeCount;
      tCat.min =
        tCat.min < minActual
          ? (tCat.min = tCat.min + (count ? (count > minActual ? minActual : count) : 1))
          : tCat.min;
      return tCat;
    } else {
      return tCat;
    }
  });

  return retCounter;
};

export const resetLimit = (counter, tCat) => {};

const createNewHolidayDate = (date) => {
  // this func creates a new date with currentYear if currentYear and holiday's DateYear are not equal
  // console.log("date", date);
  const dateObject = new Date();
  const currentYear = dateObject.getFullYear();

  const splittedHolidayDate = date.split("-");
  // console.log("splittedHolidayDate", splittedHolidayDate);

  const holidayYear = splittedHolidayDate[0];
  let newHolidayDate = null;

  if (holidayYear !== currentYear) {
    newHolidayDate = currentYear + "-" + splittedHolidayDate[1] + "-" + splittedHolidayDate[2];
  } else {
    newHolidayDate = date;
  }
  // console.log('newHolidayDate', newHolidayDate)
  // return newHolidayDate;
  return dayjs(newHolidayDate).format("YYYY-MM-DD");
};
export const TodayTiming = (openTimings, holidayTimings, serverDate) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const d = new Date(serverDate);
  const today = days[d.getDay()];

  const todayDate =
    d.getFullYear() +
    "-" +
    (d.getMonth() < 9 ? "0" + (+d.getMonth() + 1) : +d.getMonth() + 1) +
    "-" +
    (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

  const todaysFormatted = dayjs(todayDate).format("YYYY-MM-DD");
  const holiday = holidayTimings.find((h) => createNewHolidayDate(h.date) === todaysFormatted);
  const todayTime = openTimings.find((timings) => timings.day === today);
  let todayTiming = null;
  if (holiday) {
    todayTiming = {
      holiday: true,
      id: holiday.id,
      open: holiday.open,
      day: holiday.day,
      timings: holiday.timings,
      deliveryTimings: todayTime?.deliveryTimings,
      pickupTimings: todayTime?.pickupTimings,
    };
  } else {
    todayTiming = openTimings.find((timings) => timings.day === today);
  }

  return todayTiming;
};

// not being used as of 11-22-2023
export const timeDialog = (todayTiming, manualClosing, serverDate) => {
  const d = new Date(serverDate);
  const currTime = d.getHours() * 100 + d.getMinutes();
  // const currTime = "2100";
  let manuallyClosed = false;
  if (manualClosing.closed) {
    if (manualClosing.todayOnly !== "") {
      //  "2024/07/09"
      if (manualClosing.todayOnly === dayjs().format("YYYY/MM/DD")) manuallyClosed = true;
      else manuallyClosed = false;
    } else {
      manuallyClosed = true;
    }
  } else manuallyClosed = false;

  if (todayTiming.open === true && !manuallyClosed) {
    if (
      (currTime < todayTiming.openTimeDay || currTime > todayTiming.closeTimeDay) &&
      (currTime < todayTiming.openTimeEvening || currTime > todayTiming.closeTimeEvening)
    ) {
      return true;
    }
  } else {
    return true;
  }
};

export const uuidOrderItems = (items) => {
  const uuidItems = items.map((item) => {
    item.cartId = uuidv4();
    return item;
  });

  return uuidItems;
};

export const fillBestSeller = (allItems, cats) => {
  const bestItems = allItems.filter(
    // (item) => item.orderCount > 20 && item.isTimed === false && (item.price ? item.price > 3 : true)
    (item) =>
      item.orderCount > 20 &&
      cats?.find((el) => el.id === item.catId)?.type !== "offer" &&
      (item.price ? item.price > 3 : true)
  );

  function compare(a, b) {
    if (a.orderCount < b.orderCount) {
      return 1;
    }
    if (a.orderCount > b.orderCount) {
      return -1;
    }
    return 0;
  }

  bestItems.sort(compare);

  return bestItems.slice(0, 20);
};

export const compareValues = (key, order = "desc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const checkManualClosed = (manualClosing, serverDate) => {
  console.log("manualClosing", manualClosing);
  console.log("serverDate", serverDate);

  if (manualClosing?.closed) {
    console.log("Closed (remain closed)");
    return true;
  } else if (manualClosing?.todayOnly !== "") {
    console.log("TodayOnly");
    if (
      dayjs(manualClosing?.todayOnly).format("YYYY-MM-DD") ===
      dayjs(serverDate).format("YYYY-MM-DD")
    ) {
      return true;
    } else {
      return false;
    }
  } else if (manualClosing?.openingAgainIn && manualClosing?.openingAgainIn?.length > 0) {
    console.log("openingAgain");
    return checkManualClosingOpeningAgainTimes(manualClosing, serverDate);
  } else {
    console.log("else");

    return false;
  }
};

export const checkManualClosingOpeningAgainTimes = (manualClosing, serverDate) => {
  if (manualClosing?.openingAgainIn) {
    const shopManualCloseStarting = manualClosing?.openingAgainIn?.[0];
    const shopManualCloseEnding = manualClosing?.openingAgainIn?.[1];

    if (
      dayjs(serverDate).valueOf() >= shopManualCloseStarting &&
      dayjs(serverDate).valueOf() <= shopManualCloseEnding
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkTimeLies = (timings, checkCurrentTime) => {
  let timeLies = { status: false };
  // console.log("timings", timings);
  // console.log("checkCurrentTime", checkCurrentTime);

  if (timings && checkCurrentTime) {
    for (let index = 0; index < timings?.length; index++) {
      const timeArray = timings[index];
      const result = checkTime(Number(timeArray[0]), Number(timeArray[1]), checkCurrentTime);

      if (result) {
        timeLies.status = true;
        timeLies.index = index;
        timeLies.time = timeArray;
        break;
      }
      if (!result) {
        timeLies.status = false;

        if (checkCurrentTime <= timeArray[0]) {
          timeLies.message = "less";
          timeLies.index = index;
          timeLies.time = timeArray;
          if (timings.length > 1) {
            break;
          }
        } else if (checkCurrentTime >= timeArray[1]) {
          timeLies.message = "greater";
          timeLies.index = index;
          timeLies.time = timeArray;

          // break;
        }
      }
    }
  }

  return timeLies;
};

export const checkTime = (lowerLimit, upperLimit, number) => {
  if (number >= lowerLimit && number < upperLimit) {
    return true;
  } else {
    return false;
  }
};

export const setServerTimeLocal = (prevServerTime) => {
  const prevTime = prevServerTime;
  // const prevTime = 1957;

  // slice out time to get hours and minutes
  let prevTimeHours = "";
  let prevTimeMinutes = "";

  if (prevTime.toString().length === 3) {
    prevTimeHours = prevTime.toString().slice(0, 1);
    prevTimeMinutes = prevTime.toString().slice(1, 3);
  } else if (prevTime.toString().length === 4) {
    prevTimeHours = prevTime.toString().slice(0, 2);
    prevTimeMinutes = prevTime.toString().slice(2, 4);
  }

  // we normally add the time
  let newHour = prevTimeHours;
  let newMinutes = Number(prevTimeMinutes) + 5;
  let newTime =
    (newHour < 10 ? "0" + newHour : newHour) + (newMinutes < 10 ? "0" + newMinutes : newMinutes);

  // this is to check that the new format doesn't be over 59 minutes
  const timeToCheck = prevTimeHours.toString() + Number(59).toString();

  if (Number(newTime) > Number(timeToCheck)) {
    newHour = (Number(newHour) + 1).toString();
    newMinutes = newMinutes - 60;
    if (Number(newHour) > 24) {
      newHour = 1;
    }
    newTime =
      (newHour < 10 ? "0" + newHour : newHour) + (newMinutes < 10 ? "0" + newMinutes : newMinutes);
  }

  return Number(newTime);
};

// export const checkTimeBoundaries = (todayTime, checkCurrentTime) => {

//   let timeLies = { status: false };
//   for (let index = 0; index < todayTime?.timings?.length; index++) {
//     const timeArray = todayTime?.timings[index];
//     const result = checkTime(timeArray[0], timeArray[1], checkCurrentTime);

//     if (result) {
//       timeLies.status = true;
//       timeLies.index = index;
//       timeLies.time = timeArray;
//       break;
//     }
//     if (!result) {
//       timeLies.status = false;

//       if (checkCurrentTime < timeArray[0]) {
//         timeLies.message = "less";
//       } else if (checkCurrentTime > timeArray[1]) {
//         timeLies.message = "greater";
//       }
//     }
//   }
// };

// not being used
export const calculateIntervals = (preOrderStartTime, todayTiming, isCurrentlyOpen) => {
  if (todayTiming?.timings?.length < 2) {
    for (let index = 0; index < todayTiming?.timings?.length; index++) {
      const timeArray = todayTiming?.timings[index];
      const openingTime = timeArray[0];
      const closingTime = timeArray[1];

      calculateTimeIntervals(openingTime, closingTime, preOrderStartTime);
    }
  }
};

export const calculateTimeIntervals = (openingTime, closingTime, preOrderStartTime) => {
  const time = preOrderStartTime / 60;

  const isTimeInteger = time % 1 === 0;

  let preOrderTimeConverted = null;
  let singleFigureTime = null;
  let intervalsArray = [];
  let orderClosingTime = Number(closingTime);
  let orderStartingTime = Number(openingTime);

  // if the preOrder is of 120 / 60 minutes
  if (isTimeInteger) {
    singleFigureTime = time;
    orderStartingTime = orderStartingTime + Number(time.toString() + "00");
  } else {
    // if the preOrder is not 120 or 60
    const splittedTime = time.toString().split(".");

    const hour = Number(splittedTime[0]);
    let minutes = 0;
    if (preOrderStartTime === 30 || preOrderStartTime === 90) {
      minutes = Math.round(Number(splittedTime[1]) / 1.67) * 10;
    } else {
      minutes = Math.round(Number(splittedTime[1]) / 1.67);
    }

    // const minutes = Math.round(Number(splittedTime[1]) / 1.67);

    preOrderTimeConverted = Number(hour.toString() + minutes.toString());
  }

  orderStartingTime = orderStartingTime + preOrderTimeConverted;
  let stringTime = orderStartingTime.toString();

  // find hour and minutes
  // if time is less than 1000 than adjust accordingly
  let hour = "";
  let minutes = "";

  if (stringTime.length === 3) {
    hour = stringTime.slice(0, 1);
    minutes = stringTime.slice(1, 3);
  } else if (stringTime.length === 4) {
    hour = stringTime.slice(0, 2);
    minutes = stringTime.slice(2, 4);
  } else if (stringTime.length === 2) {
    hour = "00";
    minutes = stringTime.slice(0, 2);
  }
  // for (let i = 0; i < stringTime.length; i++) {
  //   if (stringTime.length === 3) {
  //     if (i === 0) {
  //       hour = stringTime[i];
  //     } else if (i === 1 || i === 2) {
  //       minutes += stringTime[i];
  //     }
  //   } else if (stringTime.length === 4) {
  //     if (i === 0 || i === 1) {
  //       hour += stringTime[i];
  //     } else if (i === 2 || i === 3) {
  //       minutes += stringTime[i];
  //     }
  //   }
  // }

  let newHour = hour;
  let newMinutes = minutes;
  if (Number(minutes) > 45) {
    let subtractedMinutes = Number(minutes) - 60;
    newHour = Number(hour) + 1;
    newMinutes = subtractedMinutes === 0 ? "00" : subtractedMinutes;
  }

  let newTime = newHour.toString() + newMinutes;
  // push initial Time to array
  intervalsArray.push(Number(newTime));
  let runLoop = true;

  while (runLoop) {
    let singleInterval = null;
    // take last element from array and add 15
    let lastIntervalAdded = intervalsArray[intervalsArray.length - 1];
    // if(lastIntervalAdded.toString().length === 3){

    // }
    singleInterval = lastIntervalAdded + 15;
    // take last and element from array and slice hour part
    let stringHour = "";
    if (lastIntervalAdded.toString().length === 3) {
      stringHour = lastIntervalAdded.toString().slice(0, 1);
    } else if (lastIntervalAdded.toString().length === 4) {
      stringHour = intervalsArray[intervalsArray.length - 1].toString().slice(0, 2);
    }

    const newHourString = (Number(stringHour) + 1).toString() + "00";
    // const newHourInteger = Number(newHourString);

    const newTime = Number(stringHour + 45);

    if (singleInterval > newTime) {
      if (newTime < orderClosingTime) {
        // intervalsArray.push();
        intervalsArray.push(Number(newHourString));
      } else {
        runLoop = false;
      }
    } else {
      if (singleInterval < orderClosingTime) {
        intervalsArray.push(singleInterval);
      } else {
        runLoop = false;
      }
    }

    singleInterval = null;
  }

  return intervalsArray;
};

export const findClosestHours = (timings, currentTime) => {
  // const timings = [...todayTimings?.timings];

  const closest = timings.reduce((prev, curr) => {
    return Math.abs(curr - currentTime) < Math.abs(prev - currentTime) ? curr : prev;
  });

  return closest;
};

export const findIntervals = (timings, currentTime, preOrderStartTime) => {
  let closestHours = findClosestHours(timings, currentTime);

  let intervals = null;

  if (timings.length < 2) {
    //  if shop has only 1 set of timings
    intervals = calculateTimeIntervals(closestHours[0], closestHours[1], preOrderStartTime);
  } else if (timings.length > 1) {
    //  if shop has more than 1 set of timings
    for (let index = 0; index < timings?.length; index++) {
      const timeArray = timings[index];
      const openingTime = timeArray[0];
      const closingTime = timeArray[1];

      const array = calculateTimeIntervals(openingTime, closingTime, preOrderStartTime);
      if (index > 0) {
        intervals = intervals.concat(array);
      } else {
        intervals = array;
      }
    }
  }

  return intervals;
};

export const checkDate = (vacationsClosing, serverDateOrg) => {
  const startDate = vacationsClosing?.startDate;
  const endDate = vacationsClosing?.endDate;
  const vacationOpeningDate = vacationsClosing?.openingDate;
  const shopClosingDate = dayjs(vacationOpeningDate).subtract(1, "day"); // Subtracts 1 day from openingDate
  const serverDate = dayjs(serverDateOrg);
  const formattedDate = serverDate.format("YYYY-MM-DD");

  let temp = false;

  if (vacationsClosing?.active) {
    if (!dayjs(formattedDate).isBefore(startDate) && !dayjs(formattedDate).isAfter(endDate)) {
      temp = true;
    } else {
      temp = false;
    }
  } else {
    temp = false;
  }

  return temp;
};

const createPrevDate = (date) => {
  let openingDay = new Date(new Date(date).valueOf() - 1000 * 60 * 60 * 24 * 1);

  let endDateMonth = openingDay.getMonth() + 1;

  let endDateYear = openingDay.getFullYear();
  let endDateDay = openingDay.getDate();

  if (endDateMonth < 10) {
    endDateMonth = "0" + endDateMonth;
  }

  if (endDateDay < 10) {
    endDateDay = "0" + endDateDay;
  }

  const nextDate = endDateYear.toString() + "-" + endDateMonth + "-" + endDateDay;

  return nextDate;
};

export const sortAreas = (sortingObj, areas) => {
  const { field, sortState } = sortingObj;

  switch (field) {
    case "area":
      if (sortState) {
        areas.sort((a, b) => (a.area.toLowerCase() > b.area.toLowerCase() ? -1 : 1));
      } else {
        areas.sort((a, b) => (a.area.toLowerCase() > b.area.toLowerCase() ? 1 : -1));
      }
      break;
    case "minOrder":
      if (sortState) {
        areas.sort((a, b) => (a.minOrderAmount > b.minOrderAmount ? -1 : 1));
      } else {
        areas.sort((a, b) => (a.minOrderAmount > b.minOrderAmount ? 1 : -1));
      }
      break;

    default:
      console.log("Invalid field!");
      break;
  }

  return areas;
};

export const checkUpcomingHolidays = (holidays, serverDate) => {
  const d = new Date(serverDate);

  const todayDate =
    d.getFullYear() +
    "-" +
    (d.getMonth() < 9 ? "0" + (+d.getMonth() + 1) : +d.getMonth() + 1) +
    "-" +
    (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
  const date = new Date(serverDate);
  const currentYear = date.getFullYear();
  const sortedHolidays = holidays.sort(compareValues("date", "asc"));
  const todayDateDAYJS = dayjs(todayDate).format("YYYY-MM-DD");
  // const dateas = "10-01"

  const modifiedHolidays = sortedHolidays.map((holiday) => {
    const splittedDate = holiday.date.split("-");

    const newDate = currentYear + "-" + splittedDate[1] + "-" + splittedDate[2];
    // console.log("newDate", newDate);
    const d = new Date(newDate);
    // console.log("d.getDay()", d.getDay());
    const holidayDay = daysArray[d.getDay()];
    // console.log("holidayDay", holidayDay);
    // dayjs
    // const newDate = splittedDate[1] + "-" + splittedDate[2];

    return {
      ...holiday,
      date: newDate,
      holidayDay,
    };
  });
  const upcomingHolidays = modifiedHolidays.filter((holiday) => holiday.date >= todayDateDAYJS);
  upcomingHolidays.sort(compareValues("date", "asc"));
  const topThree = upcomingHolidays.filter((f, i) => i < 3);
  // console.log('topThree', topThree)
  return topThree;
};

export const checkIfManifestIsPresent = (obj) => {
  const isPresent = "manifest" in obj;

  return isPresent;
};

export const calculateVoucherDiscount = (toppingSubtotal, voucherDiscount, voucherType) => {
  let afterVoucherDiscount = 0;
  if (voucherDiscount) {
    if (voucherType === "amount") {
      afterVoucherDiscount = toppingSubtotal - voucherDiscount;
    } else if (voucherType === "percent") {
      afterVoucherDiscount = toppingSubtotal - (toppingSubtotal * voucherDiscount) / 100;
    }
  } else {
    afterVoucherDiscount = toppingSubtotal;
  }

  return afterVoucherDiscount;
};

export const calculateTimings = (shopTimings) => {
  const checkArray = shopTimings.map((singleDay, index) => {
    // 0 - 5 => mon - sat
    if (index >= 0 && index < 6) {
      // increment current index by 1 to get next day timings
      const nextDayTimings = shopTimings[index + 1].timings;

      // if next day's opening time starts with 0000
      if (nextDayTimings[0][0] === "0000") {
        // initilize new times array
        let newTimes = [];
        // push all the timings of current index to new times
        singleDay.timings.map((timing) => newTimes.push(timing));
        // get starting time from current index's opening time
        let startingTime = singleDay.timings[singleDay.timings.length - 1][0];
        // get closing time from nexday's closing time
        let closeTime = nextDayTimings[0][1];

        // const closeTime = nextDayTimings[0][1];
        // create an array
        const modifiedTimes = [startingTime, closeTime];
        // make newTimes empty again for other's sake
        newTimes = [];
        // manipulate current index's timings with modifiedTimes
        return {
          ...singleDay,
          timings: [modifiedTimes],
        };
      } else {
        return singleDay;
      }
    } else {
      // for 6 => sunday
      // to get next day when current is sunday , we put 0 , as monday is at the start
      const nextDayTimings = shopTimings[0].timings;

      if (nextDayTimings[0][0] === "0000") {
        let newTimes = [];
        singleDay.timings.map((timing) => newTimes.push(timing));
        let startingTime = singleDay.timings[singleDay.timings.length - 1][0];

        let closeTime = nextDayTimings[0][1];

        // const closeTime = nextDayTimings[0][1];

        const modifiedTimes = [startingTime, closeTime];

        newTimes = [];

        return {
          ...singleDay,
          timings: [modifiedTimes],
        };
      } else {
        return singleDay;
      }
    }
  });

  // now to filter the times where a day's opening time is 0000
  const filteredArray = checkArray.map((item) => {
    // check if current index's timings are  (double timings)
    const areTimingsMultiple = item.timings.length > 1;
    // initialize empty array
    let times = [];
    // if an index's opening time is 0000
    if (item.timings[0][0] === "0000") {
      // check if this index has multiple timings
      if (areTimingsMultiple) {
        // if multiple, then skip 1st timings and push others to times array
        item.timings.map((time, index) => index > 0 && times.push(time));
      } else {
        // if timings are not multiple then push empty array as there won't be any time after manipulation
        times.push([]);
      }
      // manipulate with new times
      return {
        ...item,
        timings: times,
      };
    } else {
      return item;
    }
  });
  // return new timings
  return filteredArray;
};

export const calculateShopCloseTimings = (isShopCurrentlyOpen, shopClosingDate, serverDate) => {
  let shopStatus = false;
  // serverDate
  const currentDate = new Date(serverDate);
  const currentDateWithoutTimeZone = dateWithoutTimezone(currentDate);

  const isDifferneceOf15Minutes = isDifference15MinutesOrLess(
    shopClosingDate,
    currentDateWithoutTimeZone
  );

  if (isShopCurrentlyOpen?.status === true) {
    if (isDifferneceOf15Minutes) {
      shopStatus = true;
    } else {
      shopStatus = false;
    }
  } else {
    shopStatus = false;
  }

  return {
    shopStatus,
  };
};

export const calculateShopOpeningTimings = (isShopCurrentlyOpen, shopOpeningDate, serverDate) => {
  const currentDate = new Date(serverDate);
  const currentDateWithoutTimeZone = dateWithoutTimezone(currentDate);
  let shopStatus = false;
  // new Date(year,month,day,hours,minutes)

  if (isShopCurrentlyOpen?.status === false && isShopCurrentlyOpen?.message === "less") {
    if (currentDateWithoutTimeZone < shopOpeningDate) {
      shopStatus = true;
    } else {
      shopStatus = false;
    }
  } else {
    shopStatus = false;
  }

  return {
    shopStatus,
  };
};

function isDifference15MinutesOrLess(date1, date2) {
  // Convert the dates to milliseconds
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();

  // Calculate the difference in milliseconds
  const differenceInMillis = Math.abs(time1 - time2);

  // Convert 15 minutes to milliseconds
  const fifteenMinutesInMillis = 15 * 60 * 1000;

  // Check if the difference is 15 minutes or less
  return differenceInMillis < fifteenMinutesInMillis;
}

export const findMinutesAndHours = (time) => {
  time = time?.toString();
  let hour = "";
  let minutes = "";

  if (time.length === 3) {
    hour = time.slice(0, 1);
    minutes = time.slice(1, 3);
  } else if (time.length === 4) {
    hour = time.slice(0, 2);
    minutes = time.slice(2, 4);
  } else if (time.length === 2) {
    hour = "00";
    minutes = time.slice(0, 2);
  }
  // if (time?.length === 3) {
  //   hour = time?.slice(0, 1);
  //   minutes = time?.slice(1, 3);
  // } else if (time?.length === 4) {
  //   hour = time?.slice(0, 2);
  //   minutes = time?.slice(2, 4);
  // } else if (time?.length === 2) {
  //   hour = "00";
  //   minutes = time?.slice(0, 2);
  // }

  return [hour, minutes];
};

export const createTodayShopOpeningDateWithTime = (todayTiming, shopCurrentlyOpen) => {
  const date = new Date();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();
  const currentYear = date.getFullYear();
  const openingTime = shopCurrentlyOpen?.time?.[0];
  // const openingTime = todayTiming?.timings?.[0]?.[0];
  const minutsAndHours = findMinutesAndHours(openingTime?.toString());
  const openingHour = parseInt(minutsAndHours?.[0]);
  const openingMinutes = parseInt(minutsAndHours?.[1]);

  // new Date(year,month,day,hours,minutes)

  const shopOpeningDate = new Date(
    currentYear,
    currentMonth,
    currentDay,
    openingHour,
    openingMinutes
  );
  const withoutOffset = dateWithoutTimezone(shopOpeningDate);

  return withoutOffset;
};

export const createTodayShopClosingDateWithTime = (todayTiming, shopCurrentlyOpen) => {
  const date = new Date();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();
  const currentYear = date.getFullYear();
  const closingTime = shopCurrentlyOpen?.time?.[1];
  // const openingTime = todayTiming?.timings?.[0]?.[0];
  const minutsAndHours = findMinutesAndHours(closingTime?.toString());
  const openingHour = parseInt(minutsAndHours?.[0]);
  const openingMinutes = parseInt(minutsAndHours?.[1]);

  // new Date(year,month,day,hours,minutes)

  const shopOpeningDate = new Date(
    currentYear,
    currentMonth,
    currentDay,
    openingHour,
    openingMinutes
  );

  const withoutOffset = dateWithoutTimezone(shopOpeningDate);

  return withoutOffset;
};

export const dateWithoutTimezone = (date) => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const withoutTimezone = new Date(date.valueOf() - tzoffset).toISOString().slice(0, -1);
  return withoutTimezone;
};

export const calculatePrice = (item, discounts, orderType, currPrice) => {
  let orignalPrice = item?.price ? item.price : currPrice.price;
  let discPrice = null;

  if (!item.isDiscount) {
    console.log("here");
    if (discounts.deliveryDiscountActive && orderType === "delivery" && item.deliveryDisc) {
      discPrice = orignalPrice - orignalPrice * (discounts.deliveryDiscount / 100);
    } else if (discounts.pickupDiscountActive && orderType === "pickup" && item.pickupDisc) {
      discPrice = orignalPrice - orignalPrice * (discounts.pickupDiscount / 100);
    } else {
      discPrice = null;
    }
  } else {
    if (orderType === "delivery") {
      if (item.discounts.deliveryType === "percentage") {
        discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.delivery;
      } else {
        discPrice = orignalPrice - item.discounts.delivery;
      }
    } else {
      if (item.discounts.pickupType === "percentage") {
        discPrice = orignalPrice - (orignalPrice / 100) * item.discounts.pickup;
      } else {
        discPrice = orignalPrice - item.discounts.pickup;
      }
    }
  }
  return discPrice;
};

export const getOfferTimeBoolean = (currCat, currDateLocal, currTimeLocal) => {
  let offerMessage = "";
  let offerItemAvailable = false;
  let categoryTime = "";
  let categoryOfferTimes = null;
  if (currCat?.type === "offer") {
    let filteredDays = null;
    let offerDate = false;
    let offerDay = false;
    let offerTime = false;
    const days = [
      i18next.t("days.Sunday"),
      i18next.t("days.Monday"),
      i18next.t("days.Tuesday"),
      i18next.t("days.Wednesday"),
      i18next.t("days.Thursday"),
      i18next.t("days.Friday"),
      i18next.t("days.Saturday"),
    ];
    filteredDays = days.filter((day, index) =>
      currCat?.offerDays.some((offerDay) => offerDay - 1 === index)
    );

    // today day
    // let temp = currDateLocal
    const d = new Date(currDateLocal);

    const today = d.getDay() + 1; //0-6

    offerDay = currCat?.offerDays.some((day) => {
      if (day === today) return true;
      else return false;
    });

    // offer days
    if (filteredDays.length > 0) {
      offerMessage += `${filteredDays.map((day) => day + " ")} `;
    }

    // offer dates
    if (currCat.offerDates !== null) {
      const startDate =
        currCat?.offerDates[0].slice(8, 10) +
        "-" +
        currCat?.offerDates[0].slice(5, 7) +
        "-" +
        currCat?.offerDates[0].slice(0, 4);
      const endDate =
        currCat?.offerDates[1].slice(8, 10) +
        "-" +
        currCat?.offerDates[1].slice(5, 7) +
        "-" +
        currCat?.offerDates[1].slice(0, 4);

      offerMessage += ` ${i18next.t("mittagMessage.from")} ${startDate} ${i18next.t(
        "mittagMessage.to"
      )} ${endDate}`;

      const offerStartDate = new Date(currCat?.offerDates[0]);
      const offerEndDate = new Date(currCat?.offerDates[1]);
      const todayDate = new Date(currDateLocal);

      if (todayDate >= offerStartDate && todayDate <= offerEndDate) {
        // offerItemAvailable = true;
        offerDate = true;
      } else {
        // offerItemAvailable = false;
        offerDate = false;
      }
    }

    // offer times
    if (currCat.offerTimes !== null) {
      offerMessage += `${i18next.t("mittagMessage.between")} ${currCat?.offerTimes?.[0]?.slice(
        0,
        5
      )} - ${currCat?.offerTimes?.[1]?.slice(0, 5)}`;
      let currTime = currTimeLocal < 1000 ? "0" + currTimeLocal : currTimeLocal;
      const offerStartTime =
        currCat?.offerTimes?.[0]?.slice(0, 2) + currCat?.offerTimes?.[0]?.slice(3, 5);
      const offerEndTime =
        currCat?.offerTimes?.[1]?.slice(0, 2) + currCat?.offerTimes?.[1]?.slice(3, 5);
      categoryOfferTimes = {
        offerStartTime,
        offerEndTime,
      };

      if (currTime < offerStartTime) {
        categoryTime = "before";
        offerTime = true;
      } else if (currTime > offerEndTime) {
        categoryTime = "after";
        offerTime = true;
      } else if (currTime >= offerStartTime && currTime <= offerEndTime) {
        // offerItemAvailable = true
        offerTime = true;
        categoryTime = "between";
      } else {
        // offerItemAvailable = false
        offerTime = false;
      }
      categoryOfferTimes.categoryTime = categoryTime;
    }

    // today is offer day - true
    if (offerDay) {
      // offerDate not null in database
      if (currCat?.offerDates !== null) {
        // today is offer date - true
        if (offerDate) {
          // offerTime not null in database
          if (currCat?.offerTimes !== null) {
            // today is offer time - true
            if (offerTime) {
              offerItemAvailable = true;
            }
            // today is not offer time - false
            else {
              offerItemAvailable = false;
            }
          }
          // offerTime null in database but offer date valid
          else {
            offerItemAvailable = true;
          }
        }
        // today is not offer date - false
        else {
          offerItemAvailable = false;
        }
      } else {
        // offerTime not null in database
        if (currCat?.offerTimes !== null) {
          // today is offer time - true
          if (offerTime) {
            offerItemAvailable = true;
          }
          // today is not offer time - false
          else {
            offerItemAvailable = false;
          }
        }
        // offerTime null in database but offer day valid
        else {
          offerItemAvailable = true;
        }
      }
    } else {
      offerItemAvailable = false;
    }
  }
  return { offerMessage, offerItemAvailable, categoryTime, categoryOfferTimes };
};

export const findToppingsPrice = (itemToAdd, toppingSizePrices, size) => {
  // Create a set of selected ids for quick lookup
  const selectedToppingIds = itemToAdd?.toppings?.map((item) => item.tId);

  const toppingsWithPrices = toppingSizePrices.filter(
    (f) => selectedToppingIds.includes(f.tId) && f.sizeId === size.id
  );
  // const toppingAndPrice = toppingsWithPrices?.filter(
  //   (top) => selectedToppingIds.includes(top.tId) && top.sizeId === size.id
  // );

  // const latestToppings = itemSelectedTops.map((selectedTop) => {
  //   return toppingsWithPrices.map((ts) => {
  //     if (selectedTop.tId === ts.tId && ts.sizeId === size.id) {
  //       return {
  //         ...selectedTop,
  //         price: ts.price,
  //       };
  //     } else {
  //       return selectedTop;
  //     }
  //   });
  // });
  const toppingsPrice = toppingsWithPrices.reduce((acc, f) => (acc += f.price), 0);

  return { toppingsPrice };
};

export const checkShopStatus = (infoObject) => {
  const {
    todayTimingToSend,
    localClientTimeToSend,
    manualClosingToSend,
    vacationsClosingSend,
    serverDateToSend,
  } = infoObject;
  // console.log("infoObject", infoObject);
  let shopVacationDialogVisible = false;
  let shopTimeDialogVisible = false;
  let orderPossibility = false;

  // console.log("vacationsClosingSend", vacationsClosingSend);
  // console.log("serverDateToSend", serverDateToSend);
  // todayTimingToSend?.timings,
  const isCurrentlyOpen = checkTimeLies(todayTimingToSend?.timings, Number(localClientTimeToSend));
  // console.log("isCurrentlyOpen", isCurrentlyOpen);
  const todayShopOpeningDateWithTime = createTodayShopOpeningDateWithTime(
    todayTimingToSend,
    isCurrentlyOpen
  );

  const todayShopClosingDateWithTime = createTodayShopClosingDateWithTime(
    todayTimingToSend,
    isCurrentlyOpen
  );

  // console.log("todayShopClosingDateWithTime", todayShopClosingDateWithTime);

  const isShopAboutToClose = calculateShopCloseTimings(
    isCurrentlyOpen,
    todayShopClosingDateWithTime,
    serverDateToSend
    // shopOpeningDate
  );

  const isShopAboutToOpen = calculateShopOpeningTimings(
    isCurrentlyOpen,
    todayShopOpeningDateWithTime,
    serverDateToSend
    // shopOpeningDateToSend
  );

  const vacationToday = checkDate(vacationsClosingSend, serverDateToSend);
  // console.log("vacationToday", vacationToday);
  const manuallyClosed = checkManualClosed(manualClosingToSend, serverDateToSend);

  // console.log("vacationToday", vacationToday);

  // to Check if user is using doube or single time in separate timings
  // if pickup timing's closing is less than delivery timings opening
  // then user is using double timing

  // since these timings cannot be double so we will pick hard coded

  // ############################ REVIEW THIS CHANGE ########################
  // console.log("todayTimingToSend.pickupTimings", todayTimingToSend.pickupTimings);
  // this is to check where does the currentTime lies in two timings
  const pickUpTimeLies = checkTimeLies(
    todayTimingToSend.pickupTimings,
    Number(localClientTimeToSend)
  );
  // console.log("pickUpTimeLies", pickUpTimeLies);
  // const pickupClosingTime = todayTimingToSend.pickupTimings?.[pickUpTimeLies?.index]?.[1];
  // console.log("pickupClosingTime", pickupClosingTime);
  const pickupClosingTime = todayTimingToSend.pickupTimings?.[0]?.[1];
  // it will always be the same (because opening time will always be at index [0][0])
  const deliveryOpeningTime = todayTimingToSend.deliveryTimings?.[0]?.[0];

  let todayPickupTimingsToSend = [...todayTimingToSend.pickupTimings];
  if (Number(pickupClosingTime) < Number(deliveryOpeningTime)) {
  console.log("Less");

    todayPickupTimingsToSend = [
      ...todayTimingToSend.pickupTimings,
      ...todayTimingToSend.deliveryTimings,
    ];
  }

  const isDeliveryTime = checkTimeLies(
    todayTimingToSend.deliveryTimings,
    Number(localClientTimeToSend)
    // Number(action.payload[5].serverHourMinuteTimeFormatString)
  );

  const isPickupTime = checkTimeLies(
    // todayTimingToSend.pickupTimings,
    todayPickupTimingsToSend,
    Number(localClientTimeToSend)
    // Number(localClientTimeToSend)

    // Number(action.payload[5].serverHourMinuteTimeFormatString)
  );

  if (vacationsClosingSend?.active && vacationToday) {
    orderPossibility = false;
    shopVacationDialogVisible = true;
  } else if (manuallyClosed) {
    // manualClosingToSend?.closed
    orderPossibility = false;
    shopTimeDialogVisible = true;
  } else if (todayTimingToSend?.open) {
    orderPossibility = true;

    if (isCurrentlyOpen?.status) {
      orderPossibility = true;
      shopTimeDialogVisible = false;
    } else {
      if (isCurrentlyOpen?.message === "less") {
        orderPossibility = true;
        shopTimeDialogVisible = true;
      } else if (isCurrentlyOpen?.message === "greater") {
        orderPossibility = false;
        shopTimeDialogVisible = true;
      }
    }
  } else if (todayTimingToSend?.open === false) {
    orderPossibility = false;
    shopTimeDialogVisible = true;
  }

  return {
    todayShopClosingDateWithTime,
    todayShopOpeningDateWithTime,
    isShopAboutToOpen,
    isShopAboutToClose,
    isDeliveryTime: isDeliveryTime,
    isPickupTime: isPickupTime,
    shopTimeDialogVisible,
    shopVacationDialogVisible,
    isCurrentlyOpen,
    vacationToday,
    manuallyClosed,
    orderPossibility,
  };
};

export const findLowestAndHighest = (array) => {
  let arrayBeforeSorting = [];
  array.map((arr) => arrayBeforeSorting.push(parseInt(arr)));

  let arrayAfterSorting = arrayBeforeSorting.sort((a, b) => a - b);
  let lowest = arrayAfterSorting[0];
  let highest = arrayAfterSorting[arrayAfterSorting.length - 1];
  return { lowest, highest };
};
